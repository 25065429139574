import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { keyBy, keys, maxBy, values } from 'lodash';

export const Axis = (props: any) => {
    const svgRef = useRef();

    const { likelihoods = [], impacts = [] } = props;

    const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
    const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

    useEffect(() => {
        const svg = d3.select(svgRef.current)

        // Add X axis - without axis line
        let xAxisGenerator = d3.axisBottom(props.x);
        xAxisGenerator.tickSize(5)
        xAxisGenerator.ticks(maxImpact)

        // Add the axis label
        svg.append("text")
            .attr("x", `${(props.width + (props.inverted ? -props.axisMargin : props.axisMargin)) / 2}`)
            .attr("y", `${props.y(1 - props.chartMargin * 2) - 5}`)
            .style("text-anchor", "middle")
            // Size
            .style("font-size", "12px")
            // Color
            .style("fill", "#FFFFFF")
            .text("Impact")


        svg.append("g")
            .attr("transform", `translate(0, ${props.y(0.96 - props.chartMargin)})`)
            .call(xAxisGenerator)
            .select("path, line")
            .remove();

        let yAxisGenerator = (props.inverted ? d3.axisRight : d3.axisLeft)(props.y);
        yAxisGenerator.tickSize(5);
        yAxisGenerator.ticks(maxLikelihood);

        svg.append("g")
            .attr("transform", `translate(${props.inverted ? props.x(maxImpact + props.chartMargin) + 3 : props.x(0.96 - props.chartMargin)}, 0)`)
            .call(yAxisGenerator)
            .select("path, line")
            .remove();

        // Make the axis lines white
        svg.selectAll(".tick text, .tick line")
            .style("fill", "white");

        // Make the ticks white
        svg.selectAll("line")
            .style("stroke", "white");

        // Add the other axis label
        svg.append("text")
            .attr("x", -props.height / 2)
            .attr("y", props.inverted ? props.width : 5)
            .attr("dy", props.inverted ? "-0.75em" : ".75em")
            .attr("transform", "rotate(-90)")
            .style("text-anchor", "start")
            // Size
            .style("font-size", "12px")
            // Color
            .style("fill", "#FFFFFF")
            .text("Likelihood")

        /* svg.append("circle")
            .attr("cx", props.x(1 - props.chartMargin))
            .attr("cy", props.y(1 - props.chartMargin))
            .attr("r", 2)
            .style("fill", "white");

        svg.append("circle")
            .attr("cx", props.x(1))
            .attr("cy", props.y(1))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact))
            .attr("cy", props.y(maxLikelihood))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact + props.chartMargin))
            .attr("cy", props.y(maxLikelihood + props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(1))
            .attr("cy", props.y(maxLikelihood))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(1 - props.chartMargin))
            .attr("cy", props.y(maxLikelihood + props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact))
            .attr("cy", props.y(1))
            .attr("r", 2)
            .style("fill", "grey");

        svg.append("circle")
            .attr("cx", props.x(maxImpact + props.chartMargin))
            .attr("cy", props.y(1 - props.chartMargin))
            .attr("r", 2)
            .style("fill", "grey"); */
    }, []);

    return (
        <svg className="axes" ref={svgRef}>{props.children}</svg>
    );
};
