import * as d3 from 'd3';
import { AxisConfig, INNER_RADIUS, RadarGrid } from './RadarGrid';
import { scenarioScore } from 'utils/ranking';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const MARGIN = 90;

type YScale = d3.ScaleRadial<number, number, never>;

type RadarProps = {
    width: number;
    height: number;
    data: any;
    axisConfig: AxisConfig[];
    onMouseOver: (d: any, x: number, y: number) => void;
    onMouseOut: () => void;
    onMouseMove?: (d: any, x: number, y: number) => void;
    onClick: (index: number) => void;
};

/*
  A react component that builds a Radar Chart for several groups in the dataset
*/
export const RadarChart = ({ width, height, data, axisConfig, onMouseOver, onMouseOut, onClick }: RadarProps) => {
    const outerRadius = Math.min(width, height) / 2 - MARGIN;
    const { t } = useTranslation();

    // The x scale provides an angle for each variable of the dataset
    const allVariableNames = axisConfig.map((axis) => axis.name);
    const xScale = d3
        .scaleBand()
        .domain(allVariableNames)
        .range([0, 2 * Math.PI]);

    // Compute the y scales: 1 scale per variable.
    // Provides the distance to the center.
    let yScales: { [name: string]: YScale } = {};
    axisConfig.forEach((axis) => {
        yScales[axis.name] = d3
            .scaleRadial()
            .domain([0, axis.max])
            .range([INNER_RADIUS, outerRadius]);
    });

    // Compute the main radar shapes, 1 per group
    const lineGenerator = d3.lineRadial();

    const getLines = (withFill = false) => {
        return sortBy(data, d => - d.score + (d.selected ? 1000 : 0)).map((series, i) => {

            const allCoordinates = axisConfig.map((axis) => {
                const yScale = yScales[axis.name];
                const angle = xScale(axis.name) ?? 0; // I don't understand the type of scalePoint. IMO x cannot be undefined since I'm passing it something of type Variable.
                const radius = yScale(series[axis.name]) ?? 0;;
                const coordinate: [number, number] = [angle, radius];

                return coordinate;
            });



            // To close the path of each group, the path must finish where it started
            // so add the last data point at the end of the array
            allCoordinates.push(allCoordinates[0]);

            const d = lineGenerator(allCoordinates);

            if (!d) {
                return;
            }

            return (
                <path
                    key={series.id || t("models.scenario.baseline")}
                    id={series.id || i}
                    d={d}
                    stroke={series.color || "#FFFFFF"}
                    strokeWidth={series.width || 1}
                    fill={series.id === "current" ? "none" : "#FFFFFF55"}
                    fillOpacity={0.1}
                    strokeLinejoin='round'
                    strokeLinecap='round'
                    style={{
                        transitionDuration: '1.3s',
                        transitionTimingFunction: 'ease-in-out',
                        transitionProperty: 'all',
                        cursor: 'pointer'
                    }}
                    onMouseOver={(e) => {
                        console.log('onMouseOver', e);
                        //onMouseOver(d, minX, minY)
                    }}
                    onMouseOut={(e) => { onMouseOut() }}
                    onClick={() => {
                        onClick(series.id);
                    }}
                />
            );
        });
    }

    const allLines = getLines(false);

    return (
        <svg width={width} height={height}>
            <g transform={'translate(' + width / 2 + ',' + height / 2 + ')'}>
                <RadarGrid
                    outerRadius={outerRadius}
                    xScale={xScale}
                    axisConfig={axisConfig}
                />
                {allLines}
            </g>
        </svg>
    );
};
