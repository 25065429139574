import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'antd';

import { compact, flatten, keyBy, maxBy, sortBy, uniqBy, values } from 'lodash';
import { Axis } from './RiskScatterFullChart/Axis';
import { PointsCloud } from './RiskScatterFullChart/PointsCloud';
import { IndicatorsPointsCloud } from './RiskScatterFullChart/IndicatorsPointsCloud';
import { getItemAlteredByScenario } from 'utils/rules';
import { baseMargin, circlePlacementDx, circlePlacementDy, circleSpacer, indicatorsMargin } from './RiskScatterFullChart.functions';

import './RiskScatterFullChart.sass';

export const RiskScatterFullChart = (props: any) => {
	const svgRef = useRef();

	const { style, likelihoods = [], impacts = [], risks = [], opportunities = [], indicators = [] } = props;

	const [hoveredRisk, setHoveredRisk] = React.useState(null);
	const [xy, setXY] = React.useState([0, 0]);


	const alteredIndicators = sortBy(
		indicators.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index
		})
		),
		'name'
	);
	const indicatorsCount = alteredIndicators.reduce((acc, indicator) => {
		const key = "" + indicator.trend
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});
	const similarIndicators = keyBy((alteredIndicators || []).map(r => ({
		...r,
		similarCount: indicatorsCount[r.trend] - 1,
		index: alteredIndicators.filter(fr => fr.trend == r.trend)
			.findIndex(d => d.id === r.id)
	})), "id");


	const risksCount = risks.reduce((acc, risk) => {
		const key = "" + risk.impact + "-" + risk.likelihood;
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});

	const similarRisks = keyBy((risks || []).map(r => ({
		...r,
		similarCount: risksCount["" + r.impact + "-" + r.likelihood] - 1,
		index: risks.filter(fr => fr.impact === r.impact && fr.likelihood === r.likelihood)
			.findIndex(d => d.id === r.id)
	})), "id");

	const opportunitiesCount = opportunities.reduce((acc, opportunity) => {
		const key = "" + opportunity.impact + "-" + opportunity.likelihood;
		acc[key] = (acc[key] || 0) + 1;
		return acc;
	}, {});

	const similarOpportunities = keyBy((opportunities || []).map(r => ({
		...r,
		similarCount: opportunitiesCount["" + r.impact + "-" + r.likelihood] - 1,
		index: opportunities.filter(fr => fr.impact === r.impact && fr.likelihood === r.likelihood)
			.findIndex(d => d.id === r.id)
	})), "id");

	const [selectedPoint, setSelectedPoint] = React.useState(null);
	const [selectedRisks, setSelectedRisks] = React.useState([]);
	const [selectedOpportunities, setSelectedOpportunities] = React.useState([]);
	const [selectedIndicators, setSelectedIndicators] = React.useState([]);

	const maxLikelihood = likelihoods && parseInt(maxBy(values(likelihoods), 'id')?.id || 3);
	const maxImpact = impacts && parseInt(maxBy(values(impacts), 'id')?.id || 3);

	const margin = { top: 30, right: 0, bottom: 40, left: 0 },
		width = props.width - margin.left - margin.right,
		height = props.height - margin.top - margin.bottom;

	const zonesMargin = 10
	const axisMargin = 40
	const chartMargin = 0.25
	const stakeBandHeight = 80

	const negativeWidth = (width - zonesMargin) / 2
	const negativeHeight = height - stakeBandHeight - zonesMargin
	const negativeProperties = {
		width: negativeWidth,
		height: negativeHeight,
		x: d3.scaleLinear()
			.domain([1 - chartMargin, maxImpact + chartMargin])
			.range([axisMargin, negativeWidth]),
		y: d3.scaleLinear()
			.domain([1 - chartMargin, maxLikelihood + chartMargin])
			.range([negativeHeight - axisMargin, 0]),
		margin: {
			top: stakeBandHeight + zonesMargin,
			right: 0,
			bottom: 0,
			left: 0
		}
	}

	const stakeBandProperties = {
		width,
		height: stakeBandHeight,
		x: d3.scaleLinear()
			.domain([0, 100])
			.range([chartMargin / 10 * width, width - (2 * axisMargin) - negativeProperties.x(3 * chartMargin)])
	}

	const positiveWidth = (width - zonesMargin) / 2
	const positiveHeight = height - stakeBandProperties.height - zonesMargin
	const positiveProperties = {
		width: positiveWidth,
		height: positiveHeight,
		x: d3.scaleLinear()
			.domain([1 - chartMargin, maxImpact + chartMargin])
			.range([0, positiveWidth - axisMargin]),
		y: d3.scaleLinear()
			.domain([1 - chartMargin, maxLikelihood + chartMargin])
			.range([positiveHeight - axisMargin, 0]),
		margin: {
			top: stakeBandProperties.height + zonesMargin,
			right: 0,
			bottom: 0,
			left: negativeWidth + zonesMargin
		}
	}

	const selectPoint = (point) => {

		console.log("selectPoint", point)
		setSelectedPoint(point);
		setSelectedIndicators([]);
		setSelectedRisks([]);
		setSelectedOpportunities([]);

		if (point.__typename === "Risk") {
			// Select all indicators from point.impactedIndicators
			if (!point.isOpportunity) {
				setSelectedRisks([point]);
				setSelectedOpportunities(uniqBy(compact(opportunities.filter(o => o.id === point.matchingOpportunityId)), "id"));
			} else {
				setSelectedOpportunities([point]);
				setSelectedRisks(uniqBy(compact(risks.filter(r => r.matchingOpportunityId === point.id)), "id"));
			};
			setSelectedIndicators(uniqBy(compact(point.impactedIndicators.map(i => similarIndicators[i.indicatorId])), "id"));
		}

		if (point.__typename === "Indicator") {
			// Select all risks and opportunities from point.impactedRisks and point.impactedOpportunities
			setSelectedIndicators([point]);
			setSelectedRisks(uniqBy(compact(risks.filter(r => r.impactedIndicators.map(ii => ii.indicatorId).includes(point.id))), "id"));
			setSelectedOpportunities(uniqBy(compact(opportunities.filter(r => r.impactedIndicators.map(ii => ii.riskId).includes(point.id))), "id"));
		}

		console.log("selectedRisks", selectedRisks, "selectedOpportunities", selectedOpportunities, "selectedIndicators", selectedIndicators);
	}

	useEffect(() => {
		const svg = d3.select(svgRef.current)
	}, []);

	const stakeBandXY = (point) => {

		console.log("stakeBandXY point", point);
		if (!point) return null
		return [
			axisMargin + stakeBandProperties.x(point.trend) +
			circleSpacer(similarIndicators[point.id].similarCount) * (stakeBandProperties.width * indicatorsMargin) * circlePlacementDx(similarIndicators[point.id], similarIndicators)
			,
			stakeBandProperties.height / 2 +
			circleSpacer(similarIndicators[point.id].similarCount) * (stakeBandProperties.width * indicatorsMargin) * circlePlacementDy(similarIndicators[point.id], similarIndicators)

		]
	}

	const riskXY = (point) => {
		console.log("riskXY point", point);
		if (!point) return null
		return [
			negativeProperties.x(point.impact) +
			circleSpacer(similarRisks[point.id].similarCount) * circlePlacementDx(similarRisks[point.id], similarRisks) * negativeProperties.width * baseMargin,
			negativeProperties.y(point.likelihood) +
			circleSpacer(similarRisks[point.id].similarCount) * circlePlacementDy(similarRisks[point.id], similarRisks) * negativeProperties.width * baseMargin + negativeProperties.margin.top
		]
	}

	const opportunityXY = (point) => {
		console.log("opportunityXY point", point);
		if (!point) return null
		return [
			positiveProperties.x(point.impact) +
			circleSpacer(similarOpportunities[point.id].similarCount) * circlePlacementDx(similarOpportunities[point.id], similarOpportunities) * positiveProperties.width * baseMargin + positiveProperties.margin.left,
			positiveProperties.y(point.likelihood) +
			circleSpacer(similarOpportunities[point.id].similarCount) * circlePlacementDy(similarOpportunities[point.id], similarOpportunities) * positiveProperties.width * baseMargin + positiveProperties.margin.top
		]
	}

	return (
		<div id="RiskScatterFullChart" style={{ ...style, position: "relative" }}>

			<svg
				width={width + margin.left + margin.right}
				height={height + margin.top + margin.bottom}
				style={{
					...style || {},
					width: "100%",
				}}
				ref={svgRef}
			>

				<defs>
					<radialGradient id="gradientNegative" cx="95%" cy="5%" r="100%" >
						<stop offset="50%" stopColor={"#E70549"} stopOpacity={1} style={{
							transition: "stop-color 0.5s ease-out"
						}} />
						<stop className="color" offset="240%" stopColor="#FFFFFF" stopOpacity={1} />
					</radialGradient>
					<radialGradient id="gradientPositive" cx="95%" cy="5%" r="100%" >
						<stop offset="50%" stopColor={"#24CCB8"} stopOpacity={1} style={{
							transition: "stop-color 0.5s ease-out"
						}} />
						<stop className="color" offset="240%" stopColor="#FFFFFF" stopOpacity={1} />
					</radialGradient>
					<linearGradient id="gradientStakeBand" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" stopColor="#E70549" />
						<stop offset="100%" stopColor="#24CCB8" />
					</linearGradient>
					<linearGradient id="gradientInfluence" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" stopColor="#FFA500" />
						<stop offset="50%" stopColor="#FFdd00" />
						<stop offset="100%" stopColor="#FFA500" />
					</linearGradient>
				</defs>

				<g transform={`translate(${axisMargin}, 0)`}>
					<rect
						width={stakeBandProperties.width - (axisMargin) - negativeProperties.x(3 * chartMargin)}
						height={stakeBandProperties.height}
						x={0}
						y={0}
						fill='url(#gradientStakeBand)'
					></rect>
				</g>
				<g transform={`translate(0, ${negativeProperties.margin.top})`}>
					<rect
						className="background"
						x={negativeProperties.x(1 - chartMargin)}
						y={0}
						width={negativeProperties.width - negativeProperties.x(1 - chartMargin)}
						height={negativeProperties.y(1 - chartMargin)}
						fill="url(#gradientNegative)"
						style={{
							transition: "fill 2.5s"
						}}
					></rect>
					<text x={(negativeProperties.width / 2) + 20} y={negativeProperties.y(1 - chartMargin) / 2 + 14} style={{ fontSize: "70", fontWeight: "bold" }} fill="#00000011" textAnchor='middle'>RISKS</text>
				</g>
				<g transform={`translate(${positiveProperties.margin.left}, ${positiveProperties.margin.top})`}>
					<rect
						className="background"
						x={0}
						y={0}
						width={positiveProperties.x(maxImpact + chartMargin)}
						height={positiveProperties.y(1 - chartMargin)}
						fill="url(#gradientPositive)"
						style={{
							transition: "fill 2.5s"
						}}
					></rect>
					<text x={(positiveProperties.width / 2) + -20} y={positiveProperties.y(1 - chartMargin) / 2} style={{ fontSize: "40", fontWeight: "bold" }} fill="#00000011" textAnchor='middle'>OPPORTUNITIES</text>
					<text x={-140} y={-23} style={{ fontSize: "70", fontWeight: "bold" }} fill="#00000011">STAKES</text>
				</g>

				{selectedPoint && flatten([selectedIndicators, selectedRisks, selectedOpportunities]).map((targetObject, index) => {

					if (selectPoint && selectPoint.id && targetObject && targetObject.id && selectPoint.id === targetObject.id) return null;
					if (!targetObject) return null;

					const origin = getItemAlteredByScenario(selectedPoint.__typename === "Indicator" ? similarIndicators[selectedPoint.id] : selectedPoint.isOpportunity ? similarOpportunities[selectedPoint.id] : similarRisks[selectedPoint.id], props.scenario);
					const originPositionFunction = selectedPoint.__typename === "Indicator" ? stakeBandXY : selectedPoint.isOpportunity ? opportunityXY : riskXY

					console.log("selectedPoint", selectedPoint, "origin", origin, "targetObject", targetObject);

					const target = getItemAlteredByScenario(similarIndicators[targetObject.id] || similarOpportunities[targetObject.id] || similarRisks[targetObject.id], props.scenario)
					const targetPositionFunction = similarIndicators[targetObject.id] ? stakeBandXY : similarOpportunities[targetObject.id] ? opportunityXY : riskXY

					console.log("origin", origin, "target", target);
					const isBetweenRisks = origin && target && origin.__typename === "Risk" && target.__typename === "Risk";
					const hasIndicatorSource = origin && origin.__typename === "Indicator";

					return origin && target && <path
						stroke={isBetweenRisks ? 'url(#gradientInfluence)' : 'orange'}
						key={`${origin.id}-${target.id}`}
						style={{ transitionDuration: "2.5s", transitionProperty: "d" }}
						className={isBetweenRisks ? 'influence' : hasIndicatorSource ? "reversed" : ""}

						strokeWidth={isBetweenRisks ? 1.5 : 1.5}
						strokeDasharray={isBetweenRisks ? "none" : "5,5"}
						d={`M${originPositionFunction(origin)[0]},${originPositionFunction(origin)[1]} L${targetPositionFunction(target)[0]},${targetPositionFunction(target)[1]}`}></path>
				})}



				<g transform={`translate(${axisMargin}, 0)`}>
					<g transform={`translate(0, ${stakeBandProperties.height / 2 - 1})`}>
						<IndicatorsPointsCloud
							data={alteredIndicators}
							width={stakeBandProperties.width - (axisMargin) - negativeProperties.x(3 * chartMargin)}
							{...stakeBandProperties}
							selectedPoints={selectedIndicators}
							onClick={(indicator) => {
								selectPoint(indicator)
							}}
						>
						</IndicatorsPointsCloud>
					</g>
				</g>

				<g transform={`translate(0, ${negativeProperties.margin.top})`}>
					<Axis
						{...negativeProperties}
						chartMargin={chartMargin}
						axisMargin={axisMargin}
						likelihoods={likelihoods}
						impacts={impacts}
					>
						<PointsCloud
							width={negativeProperties.width}
							{...negativeProperties}
							data={risks}
							likelihoods={likelihoods}
							impacts={impacts}
							isRiskView={true}
							selectedPoints={selectedRisks}
							onClick={(risk) => {
								selectPoint(risk)
							}}
						></PointsCloud>
					</Axis>
				</g>

				<g transform={`translate(${positiveProperties.margin.left}, ${positiveProperties.margin.top})`}>
					<Axis

						{...positiveProperties}
						chartMargin={chartMargin}
						axisMargin={axisMargin}
						likelihoods={likelihoods}
						impacts={impacts}
						inverted={true}
					>
						<PointsCloud
							width={negativeProperties.width}
							{...positiveProperties}
							data={opportunities}
							likelihoods={likelihoods}
							impacts={impacts}
							isRiskView={false}
							selectedPoints={selectedOpportunities}
							onClick={(risk) => {
								selectPoint(risk)
							}}
						></PointsCloud>
					</Axis>
				</g>

			</svg>

			<Tooltip className="tooltip" style={{ zIndex: 1000 }} title={hoveredRisk?.id && <div>
				<b>{hoveredRisk ? hoveredRisk.name : null}</b>

				<div className="mt-2">
					Impact: {impacts[parseInt(hoveredRisk.impact)] ? hoveredRisk.impact + " - " + impacts[parseInt(hoveredRisk.impact)]?.name : hoveredRisk.impact}
				</div>
				<div className="mb-2">
					Likelihood: {likelihoods[parseInt(hoveredRisk.likelihood)] ? hoveredRisk.likelihood + " - " + likelihoods[parseInt(hoveredRisk.likelihood)]?.name : hoveredRisk.likelihood}
				</div>

				<div className='text-center'>
					<small>
						Click to edit
					</small>
				</div>
			</div>} open={hoveredRisk}>
				<div id="Tooltip-root" key={hoveredRisk?.id || "empty"}
					style={{ zIndex: 1000, position: "absolute", top: xy ? xy[1] + margin.top - 15 + "px" : 0, left: xy ? xy[0] + margin.left + "px" : 0 }}>

				</div>
			</Tooltip>




		</div >
	);
};
