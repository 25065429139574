import * as d3 from 'd3';

const textSpacerX = d3.scaleLinear().domain([2, 9]).range([0.2, 0.25]);
const textSpacerY = d3.scaleLinear().domain([2, 9]).range([0.23, 0.3]);
const circleSpacer = d3.scaleLinear().domain([2, 9]).range([0.08, 0.18]);

const baseMargin = 0.19;
const indicatorsMargin = 0.12;

const indicatorTextSpacerX = d3.scaleLinear().domain([2, 9]).range([0.2, 0.3]);
const indicatorTextSpacerY = d3.scaleLinear().domain([2, 9]).range([0.18, 0.3]);
const indicatorCircleSpacer = d3
	.scaleLinear()
	.domain([2, 9])
	.range([0.08, 0.18]);

const circlePlacementDx = (d, similarIndicators) => {
	// Répartition des points en fonction de leur index en cercle autour du point d'origine 0, 0
	const similarCount = similarIndicators[d.id].similarCount;
	const index = similarIndicators[d.id].index;

	if (similarCount == 0) return 0;

	const angle = (2 * Math.PI * index) / (similarCount + 1);
	const dx = Math.sin(angle);

	return dx;
};

const circlePlacementDy = (d, similarIndicators) => {
	// Répartition des points en fonction de leur index en cercle autour du point d'origine 0, 0
	const similarCount = similarIndicators[d.id].similarCount;
	const index = similarIndicators[d.id].index;

	if (similarCount == 0) return 0;

	const angle = (2 * Math.PI * index) / (similarCount + 1);
	const dy = Math.cos(angle);

	return dy;
};

const textDx = (d, similarIndicators, width, margin) => {
	if (Math.abs(circlePlacementDx(d, similarIndicators) * 10) < 0.1) return 0;

	let count = Math.min(similarIndicators[d.id].similarCount, 9);
	if (circlePlacementDx(d, similarIndicators) > 0)
		return (
			textSpacerX(count) *
			(width * margin) *
			circlePlacementDx(d, similarIndicators)
		);
	if (circlePlacementDx(d, similarIndicators) < 0)
		return (
			textSpacerX(count) *
			(width * margin) *
			circlePlacementDx(d, similarIndicators)
		);
	return 0;
};

const textDy = (d, similarIndicators, width, margin) => {
	if (similarIndicators[d.id].similarCount == 0) return 0;

	let count = Math.min(similarIndicators[d.id].similarCount, 9);
	return (
		textSpacerY(count) *
			(width * margin) *
			circlePlacementDy(d, similarIndicators) +
		11
	);
};

const circleDx = (d, similarIndicators, width, margin) => {
	if (Math.abs(circlePlacementDx(d, similarIndicators) * 10) < 0.1) return 0;

	let count = Math.min(similarIndicators[d.id].similarCount, 10);
	if (circlePlacementDx(d, similarIndicators) > 0)
		return (
			circleSpacer(count) *
			(width * margin) *
			circlePlacementDx(d, similarIndicators)
		);
	if (circlePlacementDx(d, similarIndicators) < 0)
		return (
			circleSpacer(count) *
			(width * margin) *
			circlePlacementDx(d, similarIndicators)
		);
	return 0;
};

const circleDy = (d, similarIndicators, width, margin) => {
	if (similarIndicators[d.id].similarCount == 0) return 0;

	let count = Math.min(similarIndicators[d.id].similarCount, 9);
	return (
		circleSpacer(count) *
		(width * margin) *
		circlePlacementDy(d, similarIndicators)
	);
};

export {
	textSpacerX,
	textSpacerY,
	circleSpacer,
	baseMargin,
	circlePlacementDx,
	circlePlacementDy,
	textDx,
	textDy,
	circleDx,
	circleDy,
	indicatorsMargin
};
